import React from 'react'

export const KS3 = () => {
  return (
    <div>
        <h3>Key Stage 3</h3>

    </div>
  )
}
