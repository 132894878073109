/*const config = {
    ApiURLDevelopment: "http://localhost:3001/api/",
    ApiURLProduction: "https://dash.school/api/",
    GoogleLoginURLDevelopment:
        "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=241045739486-7hd2nrgg7c23qvse6jlo4uda55vbeu02.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FLoginReturn", //http%3A%2F%2Flocalhost%3A3000%2FLoginReturn
    GoogleLoginURLProduction:
        "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=241045739486-7hd2nrgg7c23qvse6jlo4uda55vbeu02.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fdash.school%2FLoginReturn",
};*/

const config = {
    ApiURLDevelopment: "http://localhost:3001/api/",
    ApiURLProduction: "https://dashboard.nsg.northants.sch.uk/api/",
    GoogleLoginURLDevelopment:
        "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=241045739486-7hd2nrgg7c23qvse6jlo4uda55vbeu02.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FLoginReturn", //http%3A%2F%2Flocalhost%3A3000%2FLoginReturn
    GoogleLoginURLProduction:
        "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=241045739486-7hd2nrgg7c23qvse6jlo4uda55vbeu02.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fdashboard.nsg.northants.sch.uk%2FLoginReturn",
};


export default config;
