import React from "react";
import { Navigate } from "react-router-dom";
import { checkAuthenticated } from "./../../store/authentication/authSlice";
import { useEffect } from "react";
import { Loader } from "../general/Loader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//import { LoadingPage } from "../pages/LoadingPage";

export const ProtectedRoute = ({ children, AdminOnly }) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const error = useSelector((state) => state.auth.error);
    const status = useSelector((state) => state.auth.status);
    const token = localStorage.getItem("dashboarduser");

    useEffect(() => {
        dispatch(checkAuthenticated({token, checkforadmin: AdminOnly}));
    }, [dispatch, AdminOnly, token]);

    const render = () => {
        if (isAuthenticated && (status === "succeeded" )) {
            return <section className="authenticated">{children}</section>;
        } else if (status === "loading" || status === "idle") {
            return <Loader />;
        } else {
            return <Navigate to="/Login" replace={true} state={error} />;
        }
    }

    return render();
};
