import React from 'react'

export const MainSchool = () => {
  return (
    <div>
        <h3>Main School</h3>

    </div>
  )
}
