import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useDispatch } from "react-redux";
import { addFilter, addExcludeFilter } from "./../../store/dashboard/dashSlice";
import { Pie } from "react-chartjs-2";
import "./DashboardChart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({ title, filterkey, data, size }) => {


    const dispatch = useDispatch();

    const onAddFilter = (value) => {
        dispatch(addFilter({ key: filterkey, value }));
    };

    const onContext = (e) => {    
        if (e.target.tagName === "CANVAS") {
            e.preventDefault();
        }
    };

    const onAddExcludeFilter = (value) => {
        dispatch(addExcludeFilter({ key: filterkey, value }));
    };

    const chartdata = {
        labels: data?.labels,
        datasets: [
            {
                label: "Total",
                data: data?.data,
                backgroundColor: [
                    "rgba(46, 139, 87, .8)",
                    "rgba(60, 179, 113, .6)",
                    "rgba(30, 144, 255, .8)",
                    "rgba(0, 191, 255, .6)",
                    "rgba(255, 140, 0, .8)",
                    "rgba(255, 165, 0, .6)",
                    "rgba(128, 0, 128, .8)",
                    "rgba(139, 0, 139, .6)",
                    "rgba(220, 20, 60, .8)",
                    "rgba(255, 69, 0, .6)",
                    "rgba(105, 105, 105, .8)",
                    "rgba(169, 169, 169, .6)",
                ],
                borderColor: [
                    "rgba(46, 139, 87, 1)",
                    "rgba(60, 179, 113, 1)",
                    "rgba(30, 144, 255, 1)",
                    "rgba(0, 191, 255, 1)",
                    "rgba(255, 165, 0, 1)",
                    "rgba(255, 140, 0, 1)",
                    "rgba(128, 0, 128, 1)",
                    "rgba(139, 0, 139, 1)",
                    "rgba(220, 20, 60, 1)",
                    "rgba(255, 69, 0, 1)",
                    "rgba(105, 105, 105, 1)",
                    "rgba(169, 169, 169, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        animation: false,
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                align: "center",
                labels: {
                    color: "black",
                },
            },
        },
        events: ["click", "contextmenu", "mousemove"],
        onClick: (e, ele) => {
            //e.chart.tooltip.setActiveElements([]);
            if (ele.length === 0) return;
            if (e.type === "contextmenu") {
                onAddExcludeFilter(chartdata.labels[ele[0].index]);
                
            }
            else if (e.type === "click") {
                onAddFilter(chartdata.labels[ele[0].index]);
            }
        },
    };

    const getClassNames = () => {
        if (size && size === 2) {
            return "dashboard-chart col-sm-12 col-md-6 col-lg-6 col-xxl-4 no-print-break ";
        } else {
            return "dashboard-chart col-sm-12 col-md-6 col-lg-4 col-xxl-3 no-print-break ";
        }
    };

    return (
        <div className={getClassNames()} onContextMenu={onContext}>
            <div className="dashboard-chart-inner card text-primary text-center">
                <h4 className="text-center">{title}</h4>
                <div>
                    <Pie className="print-center" options={options} data={chartdata} />
                </div>
            </div>
        </div>
    );
};
