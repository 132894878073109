import React, { useEffect } from "react";
import "./SubjectMapper.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadFaculties } from "./../../store/lists/listSlice";
import { loadSubjects } from "./../../store/admin/adminSlice";
import { updateSubjectFaculty, deleteFaculty } from "./../../store/admin/adminSlice";
import { useState } from "react";
import { addFaculty } from "./../../store/admin/adminSlice";
import { ModalDialogue } from "../general/ModalDialogue";

export const SubjectMapper = () => {
    const [newFacultyName, setNewFacultyName] = useState("");
    const faculties = useSelector((state) => state.list.faculties.data);
    const subjects = useSelector((state) => state.admin.subjects.data);
    const addFacultyStatus = useSelector((state) => state.admin.addFaculty.status);
    const [modalShow, setModalShow] = React.useState(false);
    const [facultyToDelete, setFacultyToDelete] = React.useState(-1);
    const facultyDeleteStatus = useSelector((state) => state.admin.deleteFaculty.status);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadFaculties({ filter: [] }));
        dispatch(loadSubjects({ filter: [] }));
    }, [dispatch, addFacultyStatus, facultyDeleteStatus]);

    const handleFacultyChange = (e) => {
        const subjectid = parseInt(e.target.name);
        const facultyid = parseInt(e.target.value);
        dispatch(updateSubjectFaculty({ subjectid, facultyid }));
    };

    const handleNewFaculty = () => {
        if (newFacultyName && newFacultyName.length > 2) {
            dispatch(addFaculty({ name: newFacultyName }));
            setNewFacultyName("");
        }
    };

    const handleDeleteFaculty = (id) => {
        setFacultyToDelete(id);
        setModalShow(true);
    };

    const handleDeleteConfirmed = () => {
        dispatch(deleteFaculty({ id: facultyToDelete }));
    };

    const getFacultyRows = () => {
        let rows = faculties.map((f) => {
            return (
                <th key={`f${f.id}`} style={{position: "sticky", top: 0}}>
                    {f.name}
                    <br />
                    {f.id === 1 ? (
                        (<span>-</span>)
                    ) : (
                        <a href="#df" onClick={() => handleDeleteFaculty(f.id)}>
                            delete
                        </a>
                    )}
                </th>
            );
        });
        return rows;
    };

    const getFacultyOptions = (subjectid, facultyid) => {
        let rows = faculties.map((f) => {
            return (
                <td key={`s${subjectid}-f${f.id}`}>
                    <input type="radio" name={subjectid} value={f.id} checked={f.id === facultyid} onChange={handleFacultyChange} />
                </td>
            );
        });
        return rows;
    };

    const getSubjectRows = () => {
        let rows = subjects.map((s) => {
            return (
                <tr className="subject-row" key={`s${s.id}`}>
                    <td className="table-secondary">{s.name}</td>
                    {getFacultyOptions(s.id, s.facultyid)}
                </tr>
            );
        });
        return rows;
    };

    const getFacultyCount = () => {
        return faculties.length;
    };

    const updateNewFacultyName = (e) => {
        setNewFacultyName(e.target.value);
    };

    return (
        <div>
            <p>
                Use this tool to assign subjects to Faculties. If you are missing a faculty, add it using the form below. Note that subjects
                are automatically pooled from the data feed.
            </p>
            <form className="w-50">
                <div className="form-group">
                    <input
                        className="form-control"
                        name=""
                        placeholder="Faculty name"
                        value={newFacultyName}
                        onChange={updateNewFacultyName}
                    />{" "}
                    <br/>
                    <a href="#nf" className="btn btn-primary btn-sm form-control" onClick={handleNewFaculty}>
                        + Add Faculty
                    </a>
                </div>
            </form>
            <br />
            <table className="w-100 text-center table table-hover">
                <thead className="text-center thead-dark ">
                    <tr>
                        <th className="table-secondary"></th>
                        <th colSpan={getFacultyCount()}>Faculties </th>
                    </tr>
                    <tr>
                        <th className="table-secondary"></th>
                        {getFacultyRows()}
                    </tr>
                </thead>
                <tbody>{getSubjectRows()}</tbody>
            </table>

            <ModalDialogue
                title="Delete faculty"
                bodyText="Are you sure you want to delete this faculty?"
                labelOk="Delete"
                show={modalShow}
                setShow={setModalShow}
                okCallBack={handleDeleteConfirmed}
            />
        </div>
    );
};
