import React from "react";
import "./LoginButtonGoogle.scss";
import Config from "./../../config";
import GoogleButton from "./googledark.svg";

export const LoginButtonGoogle = () => {

    let url = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? Config.GoogleLoginURLDevelopment : Config.GoogleLoginURLProduction;

    return (
        <a className="login-btn" href={url}>
            <img src={GoogleButton} alt="login button" />
        </a>
    );
};
