import { get } from "./api";

export const authApi = {
    login: async (token) => {
        let url = `login/${encodeURIComponent(token)}`;

        return get(url)
            .then((res) => {
                if (res) {
                    if (res.success) {
                        return res;
                    } else {
                        return { success: false, error: res.data };
                    }
                } else {
                    return { success: false, error: "No response from server" };
                }
            })
            .catch((error) => {
                console.error("login error", error);
                return { success: false, error: error };
            });
    },
    logout: async () => {
        let url = `logout`;

        return get(url)
            .then((res) => {
                if (res) {
                    return res;
                } else {
                    return { success: false, error: "No response from server" };
                }
            })
            .catch((error) => {
                console.error("logout error", error);
                return { success: false, error: error };
            });
    },
    check: async (token) => {
        let url = `user/check`;

        return get(url)
            .then((res) => {
                if (res) {
                    return res;
                } else {
                    return { success: false, error: "No response from server" };
                }
            })
            .catch((error) => {
                console.error("check error", error);
                return { success: false, error: error };
            });
    },
};
