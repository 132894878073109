import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadSchool } from "../../store/admin/adminSlice";
import { redirect } from "react-router-dom";
import "./MainNavBar.scss";
import logo from "./../general/favicon.ico";
import { NavItem } from "./NavItem";
import { NavSep } from "./NavSep";

export const MainNavBar = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.profile);
    const school = useSelector((state) => state.admin.school);


    useEffect(() => {
        dispatch(loadSchool());
    }, [dispatch]);

    if (!profile) {
        return redirect("/login");
    } else {
        return (
            <nav className="navbar navbar-expand-lg bg-dark dash-dark no-print" data-bs-theme="dark">
                <div className="container-fluid">
                    <span className="text-light me-5 prevent-select">
                        <img draggable="false" className="nodrag me-2" src={logo} alt="Logo" width="30" height="30" />
                        {school.data.schoolname ? school.data.schoolname : "Dash.School "}
                    </span>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarColor01"
                        aria-controls="navbarColor01"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarColor01">
                        <ul className="navbar-nav me-auto">
                            <NavItem label="Whole School" linkto="WholeSchool" />
                            <NavItem label="Key Stages" linkto="KeyStages" />
                            <NavItem label="Year Groups" linkto="YearGroups" />
                            <NavItem label="Tutor Groups" linkto="TutorGroups" />
                            <NavSep />
                            <NavItem label="Faculties" linkto="Faculties" />
                            <NavItem label="Subjects" linkto="Subjects" />
                            <NavSep />
                            <NavItem label="Students" linkto="Students" />
                            <NavItem label="Staff" linkto="Staff" />
                            <NavSep />
                            <NavItem label="Reports" linkto="Reports" />
                        </ul>
                        <div className="navbar-nav">
                            {profile.isadmin ? <NavItem faicon="gear" label="Admin" linkto="Admin" /> : null}
                            <NavItem label={profile.displayname} faicon="user" linkto="Profile" />
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
};
