import React from "react";
import { useSelector } from "react-redux";
import { NavItem } from "./../navigation/NavItem";
import { Outlet } from "react-router-dom";

export const Admin = () => {
    const profile = useSelector((state) => state.auth.profile);

    const render = () => {
        if (profile.isadmin) {
            return (
                <div>
                    <h1>Admin</h1>
                    <p>E-mail: {profile.email}</p>

                    <nav className="no-print">
                        <ul className="nav nav-pills">
                            <NavItem linkto="Users" label="Users" />
                            <NavItem linkto="Faculties" label="Faculties" />
                            <NavItem linkto="Terms" label="Terms" />
                            <NavItem linkto="DataImport" label="Data Import" />
                            <NavItem linkto="Maintenance" label="Maintenance" />
                        </ul>
                    </nav>
                    <hr />
                    <section>
                        <Outlet />
                    </section>
                </div>
            );
        } else {
            return <div>Not authorised</div>;
        }
    };

    return render();
};
