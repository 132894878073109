import React from "react";
import { NavLink } from "react-router-dom";

export const NavItem = ({label, linkto, faicon}) => {

    return (
        <li className="nav-item">
            <NavLink draggable="false" className="nav-link prevent-select" to={linkto}>
                {faicon && <i className={"fa-solid fa-" + faicon}></i>}
                {label}
            </NavLink>
        </li>
    );
};
