import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutFromServer } from "../../store/authentication/authSlice";

export const Logout = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        try {
            dispatch(logoutFromServer());
        } catch (error) {
            console.error("Logout error", error);
        }

        localStorage.removeItem("dashboarduser");
    }, [dispatch]);

    const Logout = () => {
        return <Navigate to="/Login" replace={true} />;
    };

    return Logout();
};
