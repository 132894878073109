import React from "react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ListItem } from "./../lists/ListItem";
import { SearchBar } from "./../general/SearchBar";
import { Loader } from "../general/Loader";

export const List = ({ data, loadCall, savefilterCall }) => {
    const dispatch = useDispatch();
    const timed = useRef(null);
    const filterChange = useRef(false);
    
    useEffect(() => {
        if (filterChange.current) {
            if (timed.current) {
                clearTimeout(timed.current);
            }
            timed.current = setTimeout(() => {
                filterChange.current = false;
                dispatch(loadCall({ filter: data.filter }));
            }, 500);
        } else {
            dispatch(loadCall({ filter: data.filter}));
        }
    }, [dispatch, loadCall, data.filter]);

    const dataList = () => {
        let rendered = [];
        let count = 0;
        for (const d of data.data) {
            rendered.push(<ListItem count={count++} key={d.id} id={d.id} name={d.name} context={d.context} />);
        }
        return rendered;
    };

    const searchCallback = (term) => {
        filterChange.current = true;
        dispatch(savefilterCall(term));
    };

    const renderList = () => {
        if (data.status === "idle") {
            return <div className="dash-list">{dataList()}</div>;
        } else {
            return <Loader />;
        }
    };

    return (
        <div>
            <SearchBar callback={searchCallback} filter={data.filter} />
            {renderList()}
        </div>
    );
};
