import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAttendanceLog } from "../../store/dashboard/attendanceSlice";
import { Loader } from "../general/Loader";
import { AttendanceListItem } from "../lists/AttendanceListItem";
import { setAttendanceLogOrder, setAttendanceLogIncludePresent } from "../../store/dashboard/dashSlice";
import { useRef } from "react";

export const DashboardAttendanceLog = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.attendance.attendanceLog.status);
    const attendance = useSelector((state) => state.attendance.attendanceLog.data);
    const filterChange = useRef(false);
    const timed = useRef(null);

    const filters = useSelector((state) => state.dash.filters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);

    const attendanceLogOrder = useSelector((state) => state.dash.attendanceLogOrder);
    const attendanceLogIncludePresent = useSelector((state) => state.dash.attendanceLogIncludePresent);

    const dashloadstatus = useSelector((state) => state.dash.loadStatus);

    useEffect(() => {
        if (filterChange.current) {
            if (timed.current) {
                clearTimeout(timed.current);
            }
            timed.current = setTimeout(() => {
                filterChange.current = false;
                localStorage.setItem('dash:attendancelog:order', attendanceLogOrder);
                localStorage.setItem('dash:attendancelog:present', attendanceLogIncludePresent);
                dispatch(getAttendanceLog({ include: [...filters, ...hiddenFilters], exclude: excludeFilters, order: attendanceLogOrder, present: attendanceLogIncludePresent }));
            }, 500);
        } else {
            let order = localStorage.getItem('dash:attendancelog:order') || "date";
            let present = (localStorage.getItem('dash:attendancelog:present')|| true) === "true";

            dispatch(setAttendanceLogOrder(order));
            dispatch(setAttendanceLogIncludePresent(present));
            dispatch(getAttendanceLog({ include: [...filters, ...hiddenFilters], exclude: excludeFilters, order, present }));
        }

    }, [dispatch, filters, excludeFilters, hiddenFilters, dashloadstatus, attendanceLogOrder, attendanceLogIncludePresent]);

    const items = () => {
        let itemlist = [];
        for (const c of attendance) {
            itemlist.push(<AttendanceListItem key={c.sessid} data={c} />);
        }
        return itemlist;
    };


    const orderby = (field) => {
        filterChange.current = true;
        dispatch(setAttendanceLogOrder(field));
    };

    const includePresent = (value) => {
        filterChange.current = true;
        dispatch(setAttendanceLogIncludePresent(value));
    }

    const getHoYFilters = () => {
        return (
            <div>
                <div onClick={() => includePresent(false)} className={attendanceLogIncludePresent ? "btn btn-secondary" : "btn btn-primary"}>
                    Exclude Present Marks
                </div>
                &nbsp;
                <div onClick={() => includePresent(true)} className={attendanceLogIncludePresent ? "btn btn-primary" : "btn btn-secondary"}>
                    Include Present Marks
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                <div onClick={() => orderby("orderby:name")}  className={attendanceLogOrder === "orderby:name" ? "btn btn-primary" : "btn btn-secondary"}>
                    Order by Name
                </div>
                &nbsp;
                <div onClick={() => orderby("orderby:date")}  className={attendanceLogOrder === "orderby:name" ? "btn btn-secondary" : "btn btn-primary"}>
                    Order by Date
                </div>
                <br />
                <br />
            </div>
        );
    };

    return loading === "loading" ? (
        <Loader />
    ) : (
        <div>
            <div>
                {getHoYFilters()}   
            </div>
            <table className="conduct-list table w-100 table-striped">
                <tbody>{items()}</tbody>
            </table>
        </div>
    );
};
