import React from "react";
import { Link } from "react-router-dom";
import "./ConductTableItem.scss";

export const ConductTableItem = ({data, index}) => {
    return (
        <tr className="conducttableitem">
            <td>{index}</td>
            <td>
                <Link to={`/Dashboard/Students/${data.studentid}`}> {data.student_firstname} {data.student_lastname} ({data.student_tutorgroup})</Link>
            </td>
            <td>
                {data.points}
            </td>
            <td>
                {data.incidents}
            </td>
        </tr>
    );
};
