import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLateReport } from "../../store/dashboard/attendanceSlice";
import DownloadCSV from "../general/DownloadCSV";
import { Loader } from "../general/Loader";
import { setLateReportDays, setLateReportMinutes, setLateReportIncidents, setLateReportOrder } from "../../store/dashboard/attendanceSlice";
import { useRef } from "react";
import "./Reports.scss";

export const LessonAttendance = () => {
    const dispatch = useDispatch();
    const attendanceReport = useSelector((state) => state.attendance.lateReport);
    const timed = useRef(null);
    const filterChange = useRef(false);

    useEffect(() => {
        if (filterChange.current) {
            if (timed.current) {
                clearTimeout(timed.current);
            }
            timed.current = setTimeout(() => {
                filterChange.current = false;
                localStorage.setItem('dash:latereport:days', attendanceReport.days);
                localStorage.setItem('dash:latereport:minutes', attendanceReport.minutes);
                localStorage.setItem('dash:latereport:incidents', attendanceReport.incidents);
                localStorage.setItem('dash:latereport:order', attendanceReport.order);
                dispatch(getLateReport({ days: attendanceReport.days, minutes: attendanceReport.minutes, incidents: attendanceReport.incidents, order: attendanceReport.order }));
            }, 500);
        } else {
            dispatch(setLateReportDays(localStorage.getItem('dash:latereport:days') || 7));
            dispatch(setLateReportMinutes(localStorage.getItem('dash:latereport:minutes') || 10));
            dispatch(setLateReportIncidents(localStorage.getItem('dash:latereport:incidents') || 0));
            dispatch(setLateReportOrder(localStorage.getItem('dash:latereport:order') || "minutes"));
            dispatch(getLateReport({ days: attendanceReport.days, minutes: attendanceReport.minutes, incidents: attendanceReport.incidents, order: attendanceReport.order }));
        }
    }, [dispatch, attendanceReport.days, attendanceReport.minutes, attendanceReport.incidents, attendanceReport.order]);

    const handleDaysChange = (e) => {
        if (e.target.value && !isNaN(e.target.value)) {
            filterChange.current = true;
            dispatch(setLateReportDays(e.target.value));
        }
    };

    const handleMinutesChange = (e) => {
        if (e.target.value && !isNaN(e.target.value)) {
            filterChange.current = true;
            dispatch(setLateReportMinutes(e.target.value));
        }
    };

    const handleIncidentsChange = (e) => {
        if (e.target.value && !isNaN(e.target.value)) {
            filterChange.current = true;
            dispatch(setLateReportIncidents(e.target.value));
        }
    };


    const handleOrderChange = (order) => { 
        filterChange.current = true;
        dispatch(setLateReportOrder(order));
    };

    const render = () => {
        if (attendanceReport.status === "loading") {
            return <Loader />;
        } else if (attendanceReport.status === "error") {
            return <div>Error: {attendanceReport.error}</div>;
        } else {
            return (
                <div>
                    <h2>Lesson Late Report</h2>
                    <p>
                        Students who have <input className="latereportinput" type="number" onChange={handleMinutesChange} value={attendanceReport.minutes} /> mins or
                        more of late marks, and marked <input className="latereportinput"  type="number" onChange={handleIncidentsChange} value={attendanceReport.incidents} />{" "}
                        times or more late in the last <input className="latereportinput"  type="number" onChange={handleDaysChange} value={attendanceReport.days} /> days
                    </p>
                    <div className="no-print">
                        <DownloadCSV
                            data={attendanceReport.data}
                            fileName={"attendance-report"}
                            header={["id", "firstname", "lastname", "tutor", "minslate", "totallates"]}
                        />
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Student</th>
                                <th className={`dash-table-order ${attendanceReport.order === "minutes" ? "" : "dash-table-order-selected"}`} onClick={(e) => handleOrderChange("incidents")}>Number of Lates</th>
                                <th className={`dash-table-order ${attendanceReport.order === "minutes" ? "dash-table-order-selected" : ""}`} onClick={(e) => handleOrderChange("minutes")}>Total Minutes Late</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceReport.data &&
                                attendanceReport.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Link to={`/Dashboard/Students/${item.stuid}`}>
                                                    {item.firstname} {item.lastname} ({item.tutorgroup})
                                                </Link>
                                            </td>
                                            <td>{item.timeslate}</td>
                                            <td>{item.totalmins}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    return render();
};
