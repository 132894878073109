import React from "react";
import { LoginButtonGoogle } from "../google/LoginButtonGoogle";
import { DashboardLogo } from "./../general/DashboardLogo";
import { useLocation } from "react-router-dom";

export const Login = () => {
    const err = useLocation().state?.error;
    return (
        <div className="vh-100">
            <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100 text-center">
                    <DashboardLogo />
                </div>
                <div className="row d-flex justify-content-center align-items-center h-100 text-center">
                    <h4>Dashboard</h4>
                </div>
                {err && (
                    <div className="row d-flex justify-content-center align-items-center h-100 text-center">
                        <div className="alert alert-danger" role="alert">
                            {err}
                        </div>
                    </div>
                )}
                <div className="row d-flex justify-content-center align-items-center h-100 text-center">
                    <div>
                        <LoginButtonGoogle />
                    </div>
                </div>
            </div>
        </div>
    );
};
