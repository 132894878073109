import React from "react";
import ReactDOMClient from "react-dom/client";
import { App } from "./app/App";
import { Provider } from "react-redux";
import { store } from "./app/store/store";

const container = document.querySelector("#root");
const root = ReactDOMClient.createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
