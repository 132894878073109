import React from "react";
import { DashboardLogo } from "./DashboardLogo";
import "./Loader.scss";

export const Loader = () => {
    return (
        <div className="d-flex justify-content-center">
            <DashboardLogo repeatCount={"indefinite"} />
        </div>
    );
};
