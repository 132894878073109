import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminApi } from "../../api/api.admin";
import { redirect } from "react-router-dom";

const initialState = {

    termDates: {
        data: {
            term1: {start: '', end: ''},
            term2: {start: '', end: ''},
            term3: {start: '', end: ''},
            term4: {start: '', end: ''},
            term5: {start: '', end: ''},
            term6: {start: '', end: ''},
        },
        status: "idle",
        error: "",
    },

    subjectupdate: {
        status: "idle",
        error: "",
    },
    addFaculty: {
        status: "idle",
        error: "",
    },
    addUser: {
        status: "idle",
        error: "",
    },
    deleteUser: {
        status: "idle",
        error: "",
    },
    deleteFaculty: {
        status: "idle",
        error: "",
    },
    changeUserStatus: {
        status: "idle",
        error: "",
    },
    
    users: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    subjects: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    updateTerms: {
        status: "idle",
        error: "",
    },
    resetDatabase: {
        status: "idle",
        error: "",
    },
    changeUserAdmin: {
        status: "idle",
        error: "",
    },
    school: {
        data: {},
        status: "idle",
        error: "",
    }
};

export const updateSubjectFaculty = createAsyncThunk("admin/updateSubjectFaculty", async (payload, thunkAPI) => {
    const response = await adminApi.updateSubjectFaculty(payload);
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const addFaculty = createAsyncThunk("admin/addFaculty", async (payload, thunkAPI) => {
    const response = await adminApi.addFaculty(payload);
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const addUser = createAsyncThunk("admin/addUser", async (payload, thunkAPI) => {
    const response = await adminApi.addUser(payload);
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const deleteUser = createAsyncThunk("admin/deleteUser", async (payload, thunkAPI) => {
    const response = await adminApi.deleteUser(payload);
    if (response.success) {
        return payload;
    }
    return thunkAPI.rejectWithValue(response.data);
});

export const deleteFaculty = createAsyncThunk("admin/deleteFaculty", async (payload, thunkAPI) => {
    const response = await adminApi.deleteFaculty(payload);
    if (response.success) {
        return payload;
    }
    return thunkAPI.rejectWithValue(response.data);
});


export const loadUsers = createAsyncThunk("users/loadUsers", async (payload, thunkAPI) => {
    const response = await adminApi.getUsers(payload.filter);
    if (response.success) {
        return response.data.map((user) => {
            return { ...user, id: user.userid, name: user.displayname };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});


export const loadSubjects = createAsyncThunk("subjects/loadSubjects", async (payload, thunkAPI) => {
    const response = await adminApi.getSubjects(payload.filter);
    if (response.success) {
        return response.data.map((subject) => {
            return { ...subject, id: subject.subjectid };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});


export const loadSchool = createAsyncThunk("admin/loadSchool", async (payload, thunkAPI) => {
    const response = await adminApi.getSchool();
    if (response.success) {
        return response.data;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const changeUserStatus = createAsyncThunk("admin/changeUserStatus", async (payload, thunkAPI) => {
    const response = await adminApi.changeUserStatus(payload);
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const loadTermDates = createAsyncThunk("admin/loadTermDates", async (payload, thunkAPI) => {
    const response = await adminApi.getTermDates();
    if (response.success) {
        return response.data;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const updateTermDates = createAsyncThunk("admin/updateTermDates", async (payload, thunkAPI) => {
    const response = await adminApi.updateTermDates(payload);
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const resetDatabase = createAsyncThunk("admin/resetDatabase", async (payload, thunkAPI) => {
    const response = await adminApi.resetDatabase({email: payload});
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const changeUserAdmin = createAsyncThunk("admin/changeUserAdmin", async (payload, thunkAPI) => {
    const response = await adminApi.changeUserAdmin(payload);
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateSubjectFaculty.pending, (state) => {
                state.subjectupdate.status = "loading";
            })
            .addCase(updateSubjectFaculty.fulfilled, (state, action) => {

                state.subjects.data.find((subject) => subject.id === action.payload.subjectid).facultyid = action.payload.facultyid;
                state.subjectupdate.status = "idle";
            })
            .addCase(updateSubjectFaculty.rejected, (state, action) => {
                state.subjectupdate.status = "idle";
                state.subjectupdate.error = action.payload;
            })
            .addCase(addFaculty.pending, (state) => {
                state.addFaculty.status = "loading";
            })
            .addCase(addFaculty.fulfilled, (state, action) => {
                state.addFaculty.status = "idle";
            })
            .addCase(addFaculty.rejected, (state, action) => {
                state.addFaculty.status = "idle";
                state.addFaculty.error = action.payload;
            })
            .addCase(addUser.pending, (state) => {
                state.addUser.status = "loading";
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.addUser.status = "idle";
            })
            .addCase(addUser.rejected, (state, action) => {
                state.addUser.status = "idle";
                state.addUser.error = action.payload;
            })
            .addCase(deleteUser.pending, (state) => {
                state.deleteUser.status = "loading";
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.deleteUser.status = "idle";
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.deleteUser.status = "idle";
                state.deleteUser.error = action.payload;
            })
            .addCase(deleteFaculty.pending, (state) => {
                state.deleteFaculty.status = "loading";
            })
            .addCase(deleteFaculty.fulfilled, (state, action) => {
                state.deleteFaculty.status = "idle";
            })
            .addCase(deleteFaculty.rejected, (state, action) => {
                state.deleteFaculty.status = "idle";
                state.deleteFaculty.error = action.payload;
            })
            .addCase(loadUsers.pending, (state) => {
                state.users.status = "loading";
            })
            .addCase(loadUsers.fulfilled, (state, action) => {
                state.users.status = "idle";
                state.users.data = action.payload;
            })
            .addCase(loadUsers.rejected, (state, action) => {
                state.users.status = "idle";
                state.users.error = action.payload;
            })
            .addCase(loadSubjects.pending, (state) => {
                state.subjects.status = "loading";
            })
            .addCase(loadSubjects.fulfilled, (state, action) => {
                state.subjects.status = "idle";
                state.subjects.data = action.payload;
            })
            .addCase(loadSubjects.rejected, (state, action) => {
                state.subjects.status = "idle";
                state.subjects.error = action.payload;
            })
            .addCase(changeUserStatus.pending, (state) => {
                state.changeUserStatus.status = "loading";
            })
            .addCase(changeUserStatus.fulfilled, (state, action) => {
                state.changeUserStatus.status = "idle";
            })
            .addCase(changeUserStatus.rejected, (state, action) => {
                state.changeUserStatus.status = "idle";
                state.changeUserStatus.error = action.payload;
            })
            .addCase(loadTermDates.pending, (state) => {
                state.termDates.status = "loading";
            })
            .addCase(loadTermDates.fulfilled, (state, action) => {
                state.termDates.status = "idle";
                state.termDates.data = action.payload;
            })
            .addCase(loadTermDates.rejected, (state, action) => {
                state.termDates.status = "idle";
                state.termDates.error = action.payload;
            })
            .addCase(updateTermDates.pending, (state) => {
                state.updateTerms.status = "loading";
            })
            .addCase(updateTermDates.fulfilled, (state, action) => {
                state.updateTerms.status = "idle";
            })
            .addCase(updateTermDates.rejected, (state, action) => {
                state.updateTerms.status = "idle";
                state.updateTerms.error = action.payload;
            })
            .addCase(resetDatabase.pending, (state) => {
                state.resetDatabase.status = "loading";
            })
            .addCase(resetDatabase.fulfilled, (state, action) => {
                state.resetDatabase.status = "idle";
                redirect("/login");
            })
            .addCase(resetDatabase.rejected, (state, action) => {
                state.resetDatabase.status = "idle";
                state.resetDatabase.error = action.payload;
            })
            .addCase(changeUserAdmin.pending, (state) => {
                state.changeUserAdmin.status = "loading";
            })
            .addCase(changeUserAdmin.fulfilled, (state, action) => {
                state.changeUserAdmin.status = "idle";
            })
            .addCase(changeUserAdmin.rejected, (state, action) => {
                state.changeUserAdmin.status = "idle";
                state.changeUserAdmin.error = action.payload;
            })
            .addCase(loadSchool.pending, (state) => {
                state.school.status = "loading";
            })
            .addCase(loadSchool.fulfilled, (state, action) => {
                state.school.status = "idle";
                state.school.data = action.payload;
            })
            .addCase(loadSchool.rejected, (state, action) => {
                state.school.status = "idle";
                state.school.error = action.payload;
            });

    },
});

export default adminSlice.reducer;
