import React from "react";
import { NavItem } from "./../../navigation/NavItem";
import { NavSep } from "./../../navigation/NavSep";

export const SessionAttendanceMenu = () => {
    return (
        <div id="sectionlist" className="no-print">
            <h3>Sections</h3>
            <ul className="nav nav-pills">
                <NavItem label="Whole School" linkto="WholeSchool" />
                <NavItem label="Main School" linkto="MainSchool" />
                <NavSep />
                <NavItem label="KS3" linkto="KS3" />
                <NavItem label="Year 7" linkto="Y7" />
                <NavItem label="Year 8" linkto="Y8" />
                <NavItem label="Year 9" linkto="Y9" />
                <NavSep />
                <NavItem label="KS4" linkto="KS4" />
                <NavItem label="Year 10" linkto="Y10" />
                <NavItem label="Year 11" linkto="Y11" />
                <NavSep />
                <NavItem label="Sixth Form" linkto="KS5" />
                <NavItem label="Year 12" linkto="Y12" />
                <NavItem label="Year 13" linkto="Y13" />
            </ul>
            <hr />
        </div>
    );
};
