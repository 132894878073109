import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { attendanceApi } from "../../api/api.attendance";

const initialState = {
    attendanceLog: {
        data: [],
        status: "idle",
        error: "",
    },

    lateReport: {
        data: [],
        minutes: 10,
        days: 7,
        incidents: 0,
        order: "minutes",
        status: "idle",
        error: "",
    },

    attendanceByType: {
        data: [],
        status: "idle",
        error: "",
    },
    attendanceByWeek: {
        data: [],
        status: "idle",
        error: "",
    },
};

export const getAttendanceLog = createAsyncThunk("attendance/getAttendanceLog", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getAttendanceLog(payload.include, payload.exclude, payload.order, payload.present);
    if (apiResponse.success) {
        return apiResponse.data;
    }
    return thunkAPI.rejectWithValue(apiResponse.error);
});

export const getLateReport = createAsyncThunk("attendance/getLateReport", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getLateReport(payload);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getAttendanceByType = createAsyncThunk("attendance/getAttendanceByType", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getAttendanceByType(payload.include, payload.exclude);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.mark);
        return { labels: returnLabels, data: returnData };
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
    
    
    /*const apiResponse = await attendanceApi.getAttendanceByType(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }*/
});

export const getAttendanceByWeek = createAsyncThunk("attendance/getAttendanceByWeek", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getAttendanceByWeek(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = [];
            const values = [];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                labels.push(new Date(apiResponse.data[i].data[j].label).toLocaleDateString());
                values.push(apiResponse.data[i].data[j].value);
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const attendanceSlice = createSlice({
    name: "attendance",
    initialState,
    reducers: {
        setLateReportDays: (state, action) => {
            state.lateReport.days = action.payload;
        },
        setLateReportMinutes: (state, action) => {
            state.lateReport.minutes = action.payload;
        },
        setLateReportIncidents: (state, action) => {
            state.lateReport.incidents = action.payload;
        },
        setLateReportOrder: (state, action) => {
            state.lateReport.order = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAttendanceLog.pending, (state) => {
                state.attendanceLog.status = "loading";
            })
            .addCase(getAttendanceLog.fulfilled, (state, action) => {
                state.attendanceLog.status = "idle";
                state.attendanceLog.data = action.payload;
            })
            .addCase(getAttendanceLog.rejected, (state, action) => {
                state.attendanceLog.status = "error";
                state.attendanceLog.error = action.payload;
            })
            .addCase(getLateReport.pending, (state) => {
                state.lateReport.status = "loading";
            })
            .addCase(getLateReport.fulfilled, (state, action) => {
                state.lateReport.status = "idle";
                state.lateReport.data = action.payload;
            })
            .addCase(getLateReport.rejected, (state, action) => {
                state.lateReport.status = "error";
                state.lateReport.error = action.payload;
            })
            .addCase(getAttendanceByType.pending, (state) => {
                state.attendanceByType.status = "loading";
            })
            .addCase(getAttendanceByType.fulfilled, (state, action) => {
                state.attendanceByType.status = "idle";
                state.attendanceByType.data = action.payload;
            })
            .addCase(getAttendanceByType.rejected, (state, action) => {
                state.attendanceByType.status = "error";
                state.attendanceByType.error = action.payload;
            })
            .addCase(getAttendanceByWeek.pending, (state) => {
                state.attendanceByWeek.status = "loading";
            })
            .addCase(getAttendanceByWeek.fulfilled, (state, action) => {
                state.attendanceByWeek.status = "idle";
                state.attendanceByWeek.data = action.payload;
            })
            .addCase(getAttendanceByWeek.rejected, (state, action) => {
                state.attendanceByWeek.status = "error";
                state.attendanceByWeek.error = action.payload;
            }); 
    },
});

export const { setLateReportDays, setLateReportMinutes, setLateReportOrder, setLateReportIncidents } = attendanceSlice.actions;
export default attendanceSlice.reducer;
