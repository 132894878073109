import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authApi } from "./../../api/api.auth";
import { jwtDecode } from "jwt-decode";

const initialState = {
    profile: {},
    isAuthenticated: false,
    status: "idle",
    error: "",
};

export const loginToServer = createAsyncThunk(
    "auth/loginToServer",
    async (payload, { rejectWithValue }) => {
        const response = await authApi.login(
            payload.token
        );
        if (response.success) {
            return response.data;
        } else {
            console.log(response.error);
            return rejectWithValue(response.error);
        }
    }
);

export const logoutFromServer = createAsyncThunk("auth/logoutFromServer", async (payload, {rejectWithValue}) => {
    const response = await authApi.logout();
    if (response.success) {
        return response.data;
    } else {
        return rejectWithValue(response.error);
    }
});

export const checkAuthenticated = createAsyncThunk("auth/checkAuthenticated", async (payload, {rejectWithValue}) => {
    let token = payload.token;
        if (token) {
            //Authenticated
            const user = jwtDecode(token);
            
            if (Date.now > user.exp) {
                console.log("Expired");
                return rejectWithValue("Login has expired");
            } else if (payload.checkforadmin && !user.admin) {
                console.log("Not admin");
                return rejectWithValue("You must be an admin");
            } else if (!user.active) {
                console.log("Not active");
                return rejectWithValue("User is not active");
            } else {
                const response = await authApi.check(token);
                if (!response.success) {
                    return rejectWithValue(response.error); 
                }
                return {success: true, data: user};
            }
        } else {
            return rejectWithValue("Token not found");
            
        }
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginToServer.fulfilled, (state, action) => {
                const user = jwtDecode(action.payload);
                state.profile = user;
                state.isAuthenticated = true;
                state.error = "";
                localStorage.setItem("dashboarduser", action.payload);
                state.status = "succeeded";
            })
            .addCase(loginToServer.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(loginToServer.rejected, (state, action) => {
                state.status = "failed";
                state.isAuthenticated = false;
                localStorage.removeItem("dashboarduser");
                state.error = action.payload;
            })
            .addCase(logoutFromServer.fulfilled, (state, action) => {
                state.profile = {};
                state.isAuthenticated = false;
                localStorage.removeItem("dashboarduser");
                state.status = "idle";
            })
            .addCase(logoutFromServer.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(logoutFromServer.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(checkAuthenticated.fulfilled, (state, action) => {
                state.profile = action.payload.data;
                state.isAuthenticated = true;
                state.status = "succeeded";
            })
            .addCase(checkAuthenticated.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(checkAuthenticated.rejected, (state, action) => {
                state.status = "failed";
                state.isAuthenticated = false;
                localStorage.removeItem("dashboarduser");
                state.error = action.payload;
            });
    },
});

export const { setProfile, setIsAuthenticated, setError } = authSlice.actions;

export default authSlice.reducer;
