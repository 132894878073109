import React from "react";
import { Link } from "react-router-dom";
import "./ConductListItem.scss";

export const AttendanceListItem = ({ data }) => {
    const presentClass = () => {
        if (data.present) {
            return "text-bg-success";
        } else if (!data.present && data.meaningful) {
            return "text-bg-danger";
        } else {
            return "text-bg-warning";
        }
    };

    let datelogged = new Date(data.datelogged);
    const meaningful = () => {
        if (data.meaningful) {
            return (
                <div className={"badge rounded-pill text-bg-info"}>
                    <span className="fw-bold">Noteable</span>
                </div>
            );
        }
    };
    const included = () => {
        if (!data.include) {
            return (
                <div className={"badge rounded-pill text-bg-info"}>
                    <span className="fw-bold">Not included in stats</span>
                </div>
            );
        }
    };
    const mark = () => {
        if (data.mark) {
            return <span className={"badge rounded-pill " + presentClass()}>{data.mark}</span>;
        }
    };

    const banding = () => {
        if (data.student_attendance === 100) {
            return <span className="badge rounded-pill attendance-champion">Champion</span>;
        } else if (data.student_attendance >= 97) {
            return <span className="badge rounded-pill attendance-excellent">Excellent</span>;
        } else if (data.student_attendance >= 96) {
            return <span className="badge rounded-pill attendance-impressive">Impressive</span>;
        } else if (data.student_attendance >= 95) {
            return <span className="badge rounded-pill attendance-nearlythere">Nearly</span>;
        } else if (data.student_attendance >= 94) {
            return <span className="badge rounded-pill attendance-improve">Improve</span>;
        } else if (data.student_attendance > 90) {
            return <span className="badge rounded-pill attendance-danger">Danger</span>;
        } else {
            return <span className="badge rounded-pill attendance-impact">Extreme</span>;
        }
    };

    return (
        <tr className="conduct-list-item no-print-break">
            <td className={"conduct-list-item-date conduct-list-item-date" + datelogged.getDay().toString()}>
                {datelogged.toDateString()} <br />
                {data.am ? "AM" : "PM"}
            </td>
            <td className="conduct-list-item-info"> 
            AEA: <br/>{data.student_attendance}% <br />
                {banding()}

            </td>
            <td className="conduct-list-item-data">
                <h4>
                    <Link to={`/Dashboard/Students/${data.studentid}`}>
                        {data.student_firstname} {data.student_lastname}
                    </Link>{" "}
                    (<Link to={`/Dashboard/TutorGroups/${data.student_tutorgroupid}`}>{data.student_tutorgroup}</Link>) 
                </h4>
                <div>{data.comment}</div>
                <br />
                <div>
                    {mark()}
                </div>
            </td>
            <td>
            </td>
        </tr>
    );
};
