import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addUser, loadUsers, deleteUser, changeUserStatus, changeUserAdmin } from "./../../store/admin/adminSlice";
import { useEffect } from "react";
import { useState } from "react";
import { ModalDialogue } from "../general/ModalDialogue";

export const Users = () => {
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const [userToDelete, setUserToDelete] = React.useState(-1);
    const [newUserEmail, setNewUserEmail] = useState("");
    const newUserStatus = useSelector((state) => state.admin.addUser.status);
    const deleteUserStatus = useSelector((state) => state.admin.deleteUser.status);
    const changeUserStatusStatus = useSelector((state) => state.admin.changeUserStatus.status);
    const changeUserAdminStatus = useSelector((state) => state.admin.changeUserAdmin.status);
    const users = useSelector((state) => state.admin.users.data);
    const profile = useSelector((state) => state.auth.profile);

    useEffect(() => {
        dispatch(loadUsers({ filter: [] }));
    }, [dispatch, newUserStatus, deleteUserStatus, changeUserStatusStatus, changeUserAdminStatus]);

    const handleAddUser = () => {
        if (newUserEmail && newUserEmail.length > 2) {
            dispatch(addUser({ email: newUserEmail }));
            setNewUserEmail("");
        }
    };

    const handleChangeStatus = (id, current) => {
        dispatch(changeUserStatus({ id, status: !current }));
    };

    const handleDelete = (id) => {
        setUserToDelete(id);
        setModalShow(true);
    };

    const handleDeleteConfirmed = () => {
        dispatch(deleteUser({ id: userToDelete }));
    };

    const handleMakeAdmin = (id, current) => {
        dispatch(changeUserAdmin({ id, admin: !current }));
    }

    const getRows = () => {
        let rows = users.map((u) => {
            return (
                <tr key={`u${u.id}`}>
                    <td>{u.displayname}</td>
                    <td>{u.email}</td>
                    <td className={u.isadmin ? "text-success" : "text-danger"}>{u.isadmin ? "Yes" : "No"}</td>
                    <td className={u.active ? "text-success" : "text-danger"}>{u.active ? "Yes" : "No"}</td>
                    <td>
                        {u.userid !== profile.userid ? (
                            <>
                                {" "}
                                <a href="#e" onClick={() => handleChangeStatus(u.id, u.active)}>
                                    {u.active ? "deactivate" : "activate"}
                                </a>{" "}
                                |{" "}
                                <a href="#e" onClick={() => handleMakeAdmin(u.id, u.isadmin)}>
                                    {u.isadmin ? "remove admin" : "make admin"}
                                </a>{" "}
                                |{" "}
                                <a href="#e" onClick={() => handleDelete(u.id)}>
                                    delete
                                </a>
                            </>
                        ) : (
                            "(You)"
                        )}
                    </td>
                </tr>
            );
        });
        return rows;
    };

    const onNewUserEmailChange = (e) => {
        setNewUserEmail(e.target.value);
    };

    return (
        <div>
            <p>
                Teachers that are detected on import are added as inactive users, you can activate them here by editing the record, and
                ticking 'active'. To manually add a new user, fill in their email below and press 'Add User'- they will be added as an
                'inactive' user. Additional details will be filled in from their linked account (Google/Microsoft) when they log in.
            </p>
            <form className="w-50">
                <div className="form-group">
                    <input className="form-control" type="text" placeholder="Email" value={newUserEmail} onChange={onNewUserEmailChange} />
                    <br/>
                    <a href="#au" onClick={handleAddUser} className="btn btn-sm btn-primary form-control">
                        + Add User
                    </a>
                </div>
            </form>
            <br />
            <table className="table w-100 table-hover">
                <thead className="thead-dark">
                    <tr style={{ position: "sticky", top: 0 }}>
                        <th>Display Name</th>
                        <th>E-mail</th>
                        <th>Admin</th>
                        <th>Active</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{getRows()}</tbody>
            </table>
            <ModalDialogue
                title="Delete user"
                bodyText="Are you sure you want to delete this user?"
                labelOk="Delete"
                show={modalShow}
                setShow={setModalShow}
                okCallBack={handleDeleteConfirmed}
            />
        </div>
    );
};
