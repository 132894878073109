import axios from "axios";
import config from "./../config";

export const get = async (url, responseType = "json", params = {}) => {
    let activeurl = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? config.ApiURLDevelopment : config.ApiURLProduction;
    let location = new URL(url, activeurl);
    const token = localStorage.getItem("dashboarduser");
    const apiconfig = {
        responseType,
        headers: {
            Authorization: token ? `Bearer ${token}` : "",
        },
        params,
    };
    const response = await axios
        .get(location.href, apiconfig)
        .catch(function (error) {
            if (error.response) {
                return { data: error.response.data, totalfail: true };
            } else if (error.request) {
                return { data: "No response from server", totalfail: true };
            } else {
                return { data: error.message, totalfail: true };
            }
        });
    if (response.totalfail) {
        if (response.data === "Unauthorized") {
            console.warn("Unauthorized");
        }
        return { success: false, data: response.data }
    }
    switch (responseType) { 
        default:
        case "json":
            return { success: true, data: response.data };
        case "arraybuffer":
            const data = Buffer.from(response.data, "binary").toString(
                "base64"
            );
            return { success: true, data: data };
    }
};
