import React from "react";
import { SessionAttendanceMenu } from "./SessionAttendanceItems/SessionAttendanceMenu";
import { Outlet } from "react-router-dom";

export const SessionAttendance = () => {
    return (
        <div>
            <SessionAttendanceMenu />
            <section className="dashboard-body">
                <Outlet />
            </section>
        </div>
    );
};
