import React, { useEffect } from "react";
import { ConductListItem } from "../lists/ConductListItem";
import { useDispatch, useSelector } from "react-redux";
import { getStudentAchievement } from "../../store/dashboard/achievementSlice";
import { Loader } from "../general/Loader";

export const DashboardAchievementLog = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.achievement.recordsStatus);
    const achievement = useSelector((state) => state.achievement.records);
    const filters = useSelector((state) => state.dash.filters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);

    const dashloadstatus = useSelector((state) => state.dash.loadStatus);

    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getStudentAchievement({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
    }, [dispatch, filters, excludeFilters, hiddenFilters, dashloadstatus]);

    const items = () => {
        let itemlist = [];
        for (const c of achievement) {
            itemlist.push(<ConductListItem key={c.conductid} data={c} />);
        }
        return itemlist;
    };

    
    return loading === "loading" ? (
        <Loader />
    ) : (
        <table className="conduct-list table w-100 table-striped">
            <tbody>{items()}</tbody>
        </table>
    );
};