import React, { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LoadingPage } from "./LoadingPage";
import { loginToServer } from "./../../store/authentication/authSlice"

export const LoginReturn = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.auth.status);
    const error = useSelector((state) => state.auth.error);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    let token = searchParams.get("code");
    

    useEffect(() => {
        if (status === "idle") {
            if (token) {
                dispatch(loginToServer({ token }));
            }
        }
    }, [token, status, dispatch]);

    const render = () => {
        if (status === "succeeded") {
            return <Navigate to="/Dashboard/" replace={true} />;
        } else if (status === "failed") {
            return <Navigate to="/Login" replace={true} state={{error}} />;
        } else {
            return <LoadingPage />;
        }
    };

    return render();
};
