import { configureStore } from "@reduxjs/toolkit";


import dashReducer from "./dashboard/dashSlice";
import behaviourReducer from "./dashboard/behaviourSlice";
import authReducer from "./authentication/authSlice";
import listReducer from "./lists/listSlice";
import chartsReducer from "./dashboard/chartsSlice";
import achievementReducer from "./dashboard/achievementSlice";
import adminReducer from "./admin/adminSlice";
import settingsSlice from "./admin/settingsSlice";
import attendanceSlice from "./dashboard/attendanceSlice";

export const store = configureStore({
    reducer: {
        admin: adminReducer,
        dash: dashReducer,
        behaviour: behaviourReducer,
        achievement: achievementReducer,
        auth: authReducer,
        list: listReducer,
        charts: chartsReducer,
        settings: settingsSlice,
        attendance: attendanceSlice,
    },
});
