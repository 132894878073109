import React from 'react'

export const KS4 = () => {
  return (
    <div>
        <h3>Key Stage 4</h3>

    </div>
  )
}
