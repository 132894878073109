import { get } from "./api";

export const dashApi = {
    getKeystages: async (filter) => {
        let url = `dash/keystages`;

        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getKeystage: async (id) => {
        let url = `dash/keystage/${id}`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getYearGroups: async (filter) => {
        let url = `dash/yeargroups`;

        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getYearGroup: async (id) => {
        let url = `dash/yeargroup/${id}`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getTutorGroups: async (filter) => {
        let url = `dash/tutorgroups`;

        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getTutorGroup: async (id) => {
        let url = `dash/tutorgroup/${id}`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getStudent: async (id) => {
        let url = `dash/student/${id}`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getStudents: async (filter) => {
        let url = `dash/students`;

        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getStaffMember: async (id) => {
        let url = `dash/staff/${id}`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getStaff: async (filter) => {
        let url = `dash/staff`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getFaculties: async (filter) => {
        let url = `dash/faculties`;

        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getFaculty: async (id) => {
        let url = `dash/faculty/${id}`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getSubject: async (id) => {
        let url = `dash/subject/${id}`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getSubjects: async (filter) => {
        let url = `dash/subjects`;

        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

};
