import React from "react";
import { Link } from "react-router-dom";
import "./ConductListItem.scss";

export const ConductListItem = ({ data }) => {
    let pointsClass = data.conducttype === 1 ? "text-bg-danger" : "text-bg-success";
    let datelogged = new Date(data.datelogged);
    return (
        <tr className="conduct-list-item no-print-break">
            <td className={"conduct-list-item-date conduct-list-item-date" + datelogged.getDay().toString()}>
                {datelogged.toDateString()}
            </td>
            <td className="conduct-list-item-data">
                <h4>
                    <Link to={`/Dashboard/Students/${data.studentid}`}>
                        {data.student_firstname} {data.student_lastname}
                    </Link>{" "}
                    (<Link to={`/Dashboard/TutorGroups/${data.student_tutorgroupid}`}>{data.student_tutorgroup}</Link>) - {data.category}{" "}
                </h4>
                <div >{data.comments}</div>
                <br />
                <div className="text-muted">
                    <span className="fw-bold"><Link to={`/Dashboard/Subjects/${data.subjectid}`}>{data.subjectname}</Link></span> - <Link to={`/Dashboard/Staff/${data.loggedby}`}>{data.staff_name}</Link>
                </div>
                <div className="text-muted ">
                    Action: <span className="fst-italic">{data.action}</span>
                </div>
            </td>
            <td>
                <span title="Points" className={"badge rounded-pill ms-3 " + pointsClass}>
                    {data.points}
                </span>
            </td>
        </tr>
    );
};
