import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminApi } from "../../api/api.admin";

const initialState = {
    adminsettings: {
        csv_importstudents: false,
        csv_importstaff: false,
        csv_importconduct: false,
        csv_interval: "",
        csv_start: "",
        csv_end: "",
        csv_delimiter: "",
        csv_textqualifier: "",
        csv_studentpath: "",
        csv_staffpath: "",
        csv_behaviourpath: "",
        csv_achievementpath: "",
        csv_column_student_fname: "",
        csv_column_student_lname: "",
        csv_column_student_attendance: "",
        csv_column_student_sen: "",
        csv_column_student_pp: "",
        csv_column_student_lac: "",
        csv_column_student_eal: "",
        csv_column_student_exid: "",
        csv_column_student_year: "",
        csv_column_student_tutor: "",
        csv_column_staff_dispname: "",
        csv_column_staff_code: "",
        csv_column_staff_jtitle: "",
        csv_column_staff_exid: "",
        csv_column_staff_tutor: "",
        csv_column_staff_faculty: "",
        csv_column_staff_emailin: "",
        csv_column_conduct_category: "",
        csv_column_conduct_description: "",
        csv_column_conduct_points: "",
        csv_column_conduct_date: "",
        csv_column_conduct_resolved: "",
        csv_column_conduct_staffcode: "",
        csv_column_conduct_studentid: "",
        csv_column_conduct_comment: "",
        csv_column_conduct_action: "",
        csv_column_conduct_classcode: "",
        csv_column_conduct_department: "",
        csv_column_conduct_exid: "",
    },
    status: "idle",
    error: null,
};

export const loadSettings = createAsyncThunk("settings/loadSettings", async (payload, thunkAPI) => {
    const response = await adminApi.getSettings();
    if (response.success) {
        // Fix types

        return response.data;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const updateSetting = createAsyncThunk("settings/updateSetting", async (payload, thunkAPI) => {
    const response = await adminApi.updateSetting(payload);
    if (response.success) {
        return payload;
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});



export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSettings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(loadSettings.fulfilled, (state, action) => {
                state.status = "idle";
                state.adminsettings = action.payload;
            })
            .addCase(loadSettings.rejected, (state, action) => {
                state.status = "idle";
                state.error = action.payload;
            })
            .addCase(updateSetting.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateSetting.fulfilled, (state, action) => {
                state.status = "idle";
                state.adminsettings[action.payload.name] = action.payload.value;
            })
            .addCase(updateSetting.rejected, (state, action) => {
                state.status = "idle";
                state.error = action.payload;
            });
    },
});


export default settingsSlice.reducer;
