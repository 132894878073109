import React, { useEffect } from "react";
import { ConductTableItem } from "../lists/ConductTableItem";
import { useDispatch, useSelector } from "react-redux";
import { getStudentConductTable } from "../../store/dashboard/achievementSlice";
import { setConductTableOrder } from "../../store/dashboard/achievementSlice";
import "./DashboardTable.scss";
import { Loader } from "../general/Loader";

export const DashboardAchievementTable = () => {
    const dispatch = useDispatch();
    const conduct = useSelector((state) => state.achievement.conducttable);
    const loading = useSelector((state) => state.achievement.conducttableStatus);
    const order = useSelector((state) => state.achievement.conducttableorder);
    const filters = useSelector((state) => state.dash.filters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);

    const dashloadstatus = useSelector((state) => state.dash.loadStatus);

    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getStudentConductTable({include: [...filters, ...hiddenFilters, { key: order}], exclude: excludeFilters}));
    }, [dispatch, excludeFilters, filters, hiddenFilters, order, dashloadstatus]);

    const renderItems = () => {
        const items = conduct.map((c, index) => {
            return <ConductTableItem key={c.studentid} data={c} index={index + 1} />;
        });
        return items;
    };

    const orderby = (field) => {
        dispatch(setConductTableOrder(field));
    };


    return loading === "loading" ? (
        <Loader />
    ) : (
        <div>
            <table className="table table-striped">
                <thead className="">
                    <tr>
                        <th>#</th>
                        <th>Student</th>
                        <th className={`dash-table-order ${order === "orderby:sumpoints" ? "dash-table-order-selected" : ""}`} onClick={() => orderby("orderby:sumpoints")}>Points</th>
                        <th className={`dash-table-order ${order === "orderby:count" ? "dash-table-order-selected" : ""}`} onClick={() => orderby("orderby:count")}>Incidents</th>
                    </tr>
                </thead>
                <tbody>{renderItems()}</tbody>
            </table>
        </div>
    );
};
