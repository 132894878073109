import React from "react";
import { resetDatabase } from "../../store/admin/adminSlice";
import { ModalDialogue } from "../general/ModalDialogue";
import { useDispatch, useSelector } from "react-redux";

export const Maintenance = () => {
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const profile = useSelector((state) => state.auth.profile);

    const onResetDatabase = () => {
        setModalShow(true);
    };

    const onConfirmResetDatabase = () => {
        dispatch(resetDatabase(profile.email));
    };

    return (
        <div>
            <h3>Reset database</h3>
            <p>
                Resetting the database will remove all data and restore the database to its initial state. This includes users, and you will
                be logged out. Your email address will be added back in at the end of the procedure as a new admin, but it will be the only
                user in the system.
            </p>
            <p>
                This procedure should only really be performed at the start of a new school year, and if there is a major issue with the
                database.
            </p>
            <a className="btn btn-danger" href="#!" onClick={onResetDatabase}>
                Reset database
            </a>
            <hr />
            <ModalDialogue
                title="Reset database"
                bodyText="Are you sure you want to reset the database?"
                labelOk="RESET"
                okCallBack={onConfirmResetDatabase}
                show={modalShow}
                setShow={setModalShow}
            />
        </div>
    );
};
