import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Profile = () => {

    const profile = useSelector((state) => state.auth.profile);
  return (
    <div>
        <h1>{profile.displayname}</h1>
        <p>E-mail: {profile.email}</p>
        <p>Admin: {profile.isadmin ? "Yes" : "No"}</p>
        <Link to="/Logout">Logout</Link>
    </div>
  )
}
