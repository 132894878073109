import React from "react";
import "./ListItem.scss";
import { Link } from "react-router-dom";

export const ListItem = ({ id, name, context, count }) => {

    const getContext = () => {
        return context ? `(${context})` : "";
    };
    const getClass = () => {
        return count % 2 === 0 ? "dash-list-item no-drag prevent-select dash-list-item-alt" : "dash-list-item no-drag prevent-select ";
    };
    return (
        <Link className="dash-list-item-link" to={`${id}`}>
            <div className={getClass()}>
                {name} {getContext()}
            </div>
        </Link>
    );
};
