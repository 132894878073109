import React, { useEffect, useRef } from "react";

export const ModalDialogue = ({ title, labelOk, labelClose, bodyText, closeCallBack, okCallBack, show, setShow }) => {
    let modal = useRef(null);
    useEffect(() => {
        if (!modal.current) {
            modal.current = new window.bootstrap.Modal(document.getElementById("modaldialogue"));
        }
        if (show) {
            modal.current.show();
        }
    }, [show, modal]);

    const handleOk = () => {
        if (setShow) {
            setShow(false);
        }
        modal.current.hide();
        if (okCallBack) {
            okCallBack();
        }
    };

    const handleClose = () => {
        if (setShow) {
            setShow(false);
        }
        modal.current.hide();
        if (closeCallBack) {
            closeCallBack();
        }
    };

    return (
        <div className="modal" id="modaldialogue" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {title}
                        </h5>
                  {/*       <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button> */}
                    </div>
                    <div className="modal-body">{bodyText}</div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>
                            {labelClose ?? "Close"}
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleOk}>
                            {labelOk ?? "Ok"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
