import React from "react";
import "./DashboardChart.scss";

export const Digits = ({ title, data, subtitle }) => {
    const renderData = () => {
        if (!data) {
            return null;
        }
        const elements = data.map((item, index) => {
            let value = item.value ?? 0;
            switch (item.type) {
                default:
                case "number":
                    return (
                        <div key={index}>
                            <hr />
                            <p className="text-center">{item.label}</p>
                            <h1 className="text-center">{parseFloat(value).toLocaleString() ?? 0}</h1>
                        </div>
                    );
                case "trend":
                    return (
                        <div key={index}>
                            <hr />
                            <p className="text-center">{item.label}</p>
                            <div className="text-center">
                                {item.value === "1" && (
                                    <>
                                        <h1>
                                            <i className="fa-solid fa-up-long"></i>
                                        </h1>
                                        Increasing
                                    </>
                                )}
                                {item.value === "-1" && (
                                    <>
                                        <h1>
                                            <i className="fa-solid fa-down-long"></i>
                                        </h1>
                                        Decreasing
                                    </>
                                )}
                                {item.value === "0" && (
                                    <>
                                        <h1>
                                            <i className="fa-solid fa-minus"></i>
                                        </h1>
                                        Level
                                    </>
                                )}
                            </div>
                        </div>
                    );
            }
        });

        return (
            <>
                <h4 className="text-center">{title}</h4>
                {subtitle && <div className="text-center text-muted">{subtitle}</div>}
                {elements}
            </>
        );
    };

    return (
        <div className="dashboard-chart col-sm-12 col-md-6 col-lg-4 col-xxl-3 no-print-break ">
            <div className="dashboard-chart-inner card text-primary">{renderData()}</div>
        </div>
    );
};
