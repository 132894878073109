import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { chartApi } from "../../api/api.chart";

const initialState = {
    records: [],
    recordsStatus: "idle",
    recordsError: "",
    conducttable: [],
    conducttableStatus: "idle",
    conducttableError: "",
    conducttableorder: "orderby:sumpoints",
};

export const getStudentBehaviour = createAsyncThunk("charts/getStudentBehaviour", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getStudentConduct(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getStudentConductTable = createAsyncThunk("charts/getStudentConductTable", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getStudentConductTable([...payload.include, { key: "conducttype", value: "Behaviour" }], payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});


export const behaviourSlice = createSlice({     
    name: "behaviour",
    initialState,
    reducers: {
        setConductTableOrder: (state, action) => {
            state.conducttableorder = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStudentBehaviour.pending, (state) => {
                state.recordsStatus = "loading";
            })      
            .addCase(getStudentBehaviour.fulfilled, (state, action) => {
                state.recordsStatus = "idle";
                state.records = action.payload;
            })
            .addCase(getStudentBehaviour.rejected, (state, action) => {
                state.recordsStatus = "idle";
                state.recordsError = action.payload;
            })
            .addCase(getStudentConductTable.pending, (state) => {
                state.conducttableStatus = "loading";
            })
            .addCase(getStudentConductTable.fulfilled, (state, action) => {
                state.conducttableStatus = "idle";
                state.conducttable = action.payload;
            })
            .addCase(getStudentConductTable.rejected, (state, action) => {
                state.conducttableStatus = "idle";
                state.conducttableError = action.payload;
            });
    },
});



export const selectBehaviour = (state) => state.behaviour.records;
export const { setConductTableOrder } = behaviourSlice.actions;
export default behaviourSlice.reducer;