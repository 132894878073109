import React, { useEffect } from "react";
import { PieChart } from "../charts/PieChart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Digits } from "../charts/Digits";
import { LineChart } from "../charts/LineChart";
import { BarChart } from "../charts/BarChart";
import {
    getByType,
    getPoints,
    getConductByMonth,
    getConductByTerm,
    getIncidents,
    getBySubject,
    getByWeek,
    getByClass,
    getByTutor,
    getByStaff,
    getByStudentType,
    getByEthnicity,
    getByEthnicityRate
} from "../../store/dashboard/chartsSlice";
import { Loader } from "../general/Loader";

export const DashboardAchievement = () => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.dash.filters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);

    // Dashboard data
    const byTypeData = useSelector((state) => state.charts.byType.data);
    const pointsData = useSelector((state) => state.charts.points.data);
    const incidentsData = useSelector((state) => state.charts.incidents.data);
    const monthData = useSelector((state) => state.charts.byMonth.data);
    const termData = useSelector((state) => state.charts.byTerm.data);
    const classData = useSelector((state) => state.charts.byClass.data);
    const tutorGroupData = useSelector((state) => state.charts.byTutorGroup.data);
    const staffData = useSelector((state) => state.charts.byStaff.data);
    const subjectData = useSelector((state) => state.charts.bySubject.data);
    const studentType = useSelector((state) => state.charts.byStudentType.data);
    const weekData = useSelector((state) => state.charts.byWeek.data);
    const ethnicityData = useSelector((state) => state.charts.byEthnicity.data);
    const ethnicityRateData = useSelector((state) => state.charts.byEthnicityRate.data);

    const byTypeStatus = useSelector((state) => state.charts.byType.status);
    const pointsStatus = useSelector((state) => state.charts.points.status);
    const incidentsstatus = useSelector((state) => state.charts.incidents.status);
    const monthstatus = useSelector((state) => state.charts.byMonth.status);
    const termstatus = useSelector((state) => state.charts.byTerm.status);
    const classstatus = useSelector((state) => state.charts.byClass.status);
    const tutorGroupstatus = useSelector((state) => state.charts.byTutorGroup.status);
    const staffstatus = useSelector((state) => state.charts.byStaff.status);
    const subjectstatus = useSelector((state) => state.charts.bySubject.status);
    const studentTypestatus = useSelector((state) => state.charts.byStudentType.status);
    const weekstatus = useSelector((state) => state.charts.byWeek.status);
    const ethnicityStatus = useSelector((state) => state.charts.byEthnicity.status);
    const ethnicityRateStatus = useSelector((state) => state.charts.byEthnicityRate.status);

    const dashloadstatus = useSelector((state) => state.dash.loadStatus);

    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getByType({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getPoints({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getIncidents({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getConductByMonth({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getConductByTerm({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getBySubject({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByWeek({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByClass({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByTutor({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByStaff({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByStudentType({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByWeek({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByEthnicity({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
        dispatch(getByEthnicityRate({include: [...filters, ...hiddenFilters, { key: "conducttype", value: "Achievement" }], exclude: excludeFilters}));
    }, [dispatch, filters, excludeFilters, hiddenFilters, dashloadstatus]);

    if (
        byTypeStatus === "loading" ||
        pointsStatus === "loading" ||
        incidentsstatus === "loading" ||
        monthstatus === "loading" ||
        termstatus === "loading" ||
        classstatus === "loading" ||
        tutorGroupstatus === "loading" ||
        staffstatus === "loading" ||
        subjectstatus === "loading" ||
        studentTypestatus === "loading" ||
        weekstatus === "loading" ||
        ethnicityStatus === "loading" ||
        ethnicityRateStatus === "loading"
    ) {
        return <Loader />;
    }

    return (
        <div className="dashboard-area row">
            <PieChart title="Achievement by type" filterkey="category" data={byTypeData} />
            <Digits title="Achievement Points" data={pointsData} />
            <Digits title="Achievement Awards" data={incidentsData} />
            <PieChart title="Achievement by subject" filterkey="subjectname" data={subjectData} />
            <LineChart title="Achievement by month" filterkey="month" data={monthData} />
            <BarChart title="Achievement by term" filterkey="term" data={termData} />
            <PieChart title="Achievement by class" filterkey="class" data={classData} />
            <BarChart title="Achievement by tutor group" filterkey="tutorgroupname" data={tutorGroupData} />
            <PieChart title="Achievement by staff" filterkey="staffcode" data={staffData} />
            <BarChart title="Achievement by student type" filterkey="studenttype" data={studentType} />
            <LineChart title="Achievement by week" filterkey="week" data={weekData} />
            <BarChart title="Achievement by ethnicity" filterkey="ethnicity" data={ethnicityData} />
            <BarChart title="Achievement rate by ethnicity" filterkey="ethnicity" data={ethnicityRateData} />
        </div>
    );
};
