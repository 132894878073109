import React from 'react';
import { Outlet } from "react-router-dom";
import { MainNavBar } from './../navigation/MainNavBar';

export const RootPage = () => {
  return (    
    <div className="page">
        <div className=""><MainNavBar/></div>                
        <main>
            <article className="content p-4"><Outlet/></article>
        </main>
    </div>
  )
}
