import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Loader } from "../general/Loader";
import { Digits } from "../charts/Digits";
import { LineChart } from "../charts/LineChart";
import { BarChart } from "../charts/BarChart";
import { PieChart } from "../charts/PieChart";
import { getAttendanceByType, getAttendanceByWeek } from "../../store/dashboard/attendanceSlice";

export const DashboardAttendance = () => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.dash.filters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);

    const dashloadstatus = useSelector((state) => state.dash.loadStatus);

    const byTypeData = useSelector((state) => state.attendance.attendanceByType.data);
    const byTypeStatus = useSelector((state) => state.attendance.attendanceByType.status);

    const weekData = useSelector((state) => state.attendance.attendanceByWeek.data);
    const weekstatus = useSelector((state) => state.attendance.attendanceByWeek.status);

    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getAttendanceByType({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getAttendanceByWeek({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
    }, [dispatch, dashloadstatus, filters, excludeFilters, hiddenFilters]);

    if (byTypeStatus === "loading" || weekstatus === "loading") {
        return <Loader />;
    }

    return (
        <div className="dashboard-area row">
            <PieChart title="Absent Marks" filterkey="mark" data={byTypeData} />
            <LineChart title="Attendance by week" filterkey="week" data={weekData} />
        </div>
    );
};
