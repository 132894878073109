import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loadTermDates, updateTermDates } from "./../../store/admin/adminSlice";

export const TermDates = () => {
    const dispatch = useDispatch();

    const termDates = useSelector((state) => state.admin.termDates);
    const updateTermDatesStatus = useSelector((state) => state.admin.updateTerms.status);

    useEffect(() => {
        dispatch(loadTermDates());
    }, [dispatch, updateTermDatesStatus]);

    const onDateChange = (term, type, e) => {
        dispatch(updateTermDates({ term, type, date: e.target.value }));
    };


    return (
        <div>
            <p>Set the term dates for the year here. You will need to 'tweak' these at the start of each year.</p>
            <table className="table w-100">
                <thead className="thead-dark">
                    <tr>
                        <th></th>
                        <th>Start</th>
                        <th>End</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="table-primary">Term 1</th>
                        <td><input className="form-control" type="date" value={termDates.data.term1.start} onChange={(e) => onDateChange(1, 'start', e)}/></td>
                        <td><input className="form-control" type="date" value={termDates.data.term1.end} onChange={(e) => onDateChange(1, 'end', e)}/></td>
                    </tr>
                    <tr>
                        <th className="table-primary">Term 2</th>
                        <td><input className="form-control" type="date" value={termDates.data.term2.start} onChange={(e) => onDateChange(2, 'start', e)}/></td>
                        <td><input className="form-control" type="date" value={termDates.data.term2.end} onChange={(e) => onDateChange(2, 'end', e)}/></td>
                    </tr>
                    <tr>
                        <th className="table-primary">Term 3</th>
                        <td><input className="form-control" type="date" value={termDates.data.term3.start} onChange={(e) => onDateChange(3, 'start', e)}/></td>
                        <td><input className="form-control" type="date" value={termDates.data.term3.end} onChange={(e) => onDateChange(3, 'end', e)}/></td>
                    </tr>
                    <tr>
                        <th className="table-primary">Term 4</th>
                        <td><input className="form-control" type="date" value={termDates.data.term4.start} onChange={(e) => onDateChange(4, 'start', e)}/></td>
                        <td><input className="form-control" type="date" value={termDates.data.term4.end} onChange={(e) => onDateChange(4, 'end', e)}/></td>
                    </tr>
                    <tr>
                        <th className="table-primary">Term 5</th>
                        <td><input className="form-control" type="date" value={termDates.data.term5.start} onChange={(e) => onDateChange(5, 'start', e)}/></td>
                        <td><input className="form-control" type="date" value={termDates.data.term5.end} onChange={(e) => onDateChange(5, 'end', e)}/></td>
                    </tr>
                    <tr>
                        <th className="table-primary">Term 6</th>
                        <td><input className="form-control" type="date" value={termDates.data.term6.start} onChange={(e) => onDateChange(6, 'start', e)}/></td>
                        <td><input className="form-control" type="date" value={termDates.data.term6.end} onChange={(e) => onDateChange(6, 'end', e)}/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
