import React from "react";
import "./SearchBar.scss";

export const SearchBar = ({callback, filter}) => {
    return (
        <div className="search-bar no-print">
            <input value={filter} className="form-control me-sm-2" type="search" placeholder="Search..." onChange={(e) => callback(e.target.value)} />
        </div>
    );
};
