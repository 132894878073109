import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashApi } from "./../../api/api.dash";

const initialState = {
    faculties: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    students: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    tutorgroups: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    yeargroups: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    keystages: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    staff: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
    subjects: {
        data: [],
        filter: "",
        status: "idle",
        error: "",
    },
};

export const loadSubjects = createAsyncThunk("subjects/loadSubjects", async (payload, thunkAPI) => {
    const response = await dashApi.getSubjects(payload.filter);
    if (response.success) {
        return response.data.map((subject) => {
            return { ...subject, id: subject.subjectid };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const loadStaff = createAsyncThunk("staff/loadStaff", async (payload, thunkAPI) => {
    const response = await dashApi.getStaff(payload.filter);
    if (response.success) {
        return response.data.map((staff) => {

            return { ...staff, id: staff.staffid, context: `${staff.staffcode}${staff.facultyname ? ", " + staff.facultyname : "" }` };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const loadKeystages = createAsyncThunk("faculties/loadkeystages", async (payload, thunkAPI) => {
    const response = await dashApi.getKeystages(payload.filter);
    if (response.success) {
        return response.data.map((keystage) => {
            return { ...keystage, id: keystage.keystageid };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const loadYearGroups = createAsyncThunk("faculties/loadyeargroups", async (payload, thunkAPI) => {
    const response = await dashApi.getYearGroups(payload.filter);
    if (response.success) {
        return response.data.map((yeargroup) => {
            return { ...yeargroup, id: yeargroup.yeargroupid };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const loadTutorGroups = createAsyncThunk("faculties/loadtutorgroups", async (payload, thunkAPI) => {
    const response = await dashApi.getTutorGroups(payload.filter);
    if (response.success) {
        return response.data.map((tutorgroup) => {
            return { ...tutorgroup, id: tutorgroup.tutorgroupid };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const loadFaculties = createAsyncThunk("faculties/loadfaculties", async (payload, thunkAPI) => {
    const response = await dashApi.getFaculties(payload.filter);
    if (response.success) {
        return response.data.map((faculty) => {
            return { ...faculty, id: faculty.facultyid };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});

export const loadStudents = createAsyncThunk("students/loadStudents", async (payload, thunkAPI) => {
    const response = await dashApi.getStudents(payload.filter);
    if (response.success) {
        return response.data.map((student) => {
            return { ...student, id: student.studentid, name: `${student.firstname} ${student.lastname}`, context: student.tutorgroup };
        });
    } else {
        return thunkAPI.rejectWithValue(response.data);
    }
});



export const listSlice = createSlice({
    name: "list",
    initialState,
    reducers: {
        setFacultiesFilter: (state, action) => {
            state.faculties.filter = action.payload;
        },
        setStudentsFilter: (state, action) => {
            state.students.filter = action.payload;
        },
        setTutorgroupsFilter: (state, action) => {
            state.tutorgroups.filter = action.payload;
        },
        setYeargroupsFilter: (state, action) => {
            state.yeargroups.filter = action.payload;
        },
        setKeystagesFilter: (state, action) => {
            state.keystages.filter = action.payload;
        },
        setStaffFilter: (state, action) => {
            state.staff.filter = action.payload;
        },
        setSubjectsFilter: (state, action) => {
            state.subjects.filter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadFaculties.pending, (state) => {
                state.faculties.status = "loading";
            })
            .addCase(loadFaculties.fulfilled, (state, action) => {
                state.faculties.status = "idle";
                state.faculties.data = action.payload;
            })
            .addCase(loadFaculties.rejected, (state, action) => {
                state.faculties.status = "idle";
                state.faculties.error = action.payload;
            })
            .addCase(loadStudents.pending, (state) => {
                state.students.status = "loading";
            })
            .addCase(loadStudents.fulfilled, (state, action) => {
                state.students.status = "idle";
                state.students.data = action.payload;
            })
            .addCase(loadStudents.rejected, (state, action) => {
                state.students.status = "idle";
                state.students.error = action.payload;
            })
            .addCase(loadTutorGroups.pending, (state) => {
                state.tutorgroups.status = "loading";
            })
            .addCase(loadTutorGroups.fulfilled, (state, action) => {
                state.tutorgroups.status = "idle";
                state.tutorgroups.data = action.payload;
            })
            .addCase(loadTutorGroups.rejected, (state, action) => {
                state.tutorgroups.status = "idle";
                state.tutorgroups.error = action.payload;
            })
            .addCase(loadYearGroups.pending, (state) => {
                state.yeargroups.status = "loading";
            })
            .addCase(loadYearGroups.fulfilled, (state, action) => {
                state.yeargroups.status = "idle";
                state.yeargroups.data = action.payload;
            })
            .addCase(loadYearGroups.rejected, (state, action) => {
                state.yeargroups.status = "idle";
                state.yeargroups.error = action.payload;
            })
            .addCase(loadKeystages.pending, (state) => {
                state.keystages.status = "loading";
            })
            .addCase(loadKeystages.fulfilled, (state, action) => {
                state.keystages.status = "idle";
                state.keystages.data = action.payload;
            })
            .addCase(loadKeystages.rejected, (state, action) => {
                state.keystages.status = "idle";
                state.keystages.error = action.payload;
            })
            .addCase(loadStaff.pending, (state) => {
                state.staff.status = "loading";
            })
            .addCase(loadStaff.fulfilled, (state, action) => {
                state.staff.status = "idle";
                state.staff.data = action.payload;
            })
            .addCase(loadStaff.rejected, (state, action) => {
                state.staff.status = "idle";
                state.staff.error = action.payload;
            })
            .addCase(loadSubjects.pending, (state) => {
                state.subjects.status = "loading";
            })
            .addCase(loadSubjects.fulfilled, (state, action) => {
                state.subjects.status = "idle";
                state.subjects.data = action.payload;
            })
            .addCase(loadSubjects.rejected, (state, action) => {
                state.subjects.status = "idle";
                state.subjects.error = action.payload;
            });

    },
});

export const { setFacultiesFilter, setKeystagesFilter, setStaffFilter, setStudentsFilter, setTutorgroupsFilter, setYeargroupsFilter, setSubjectsFilter } =
    listSlice.actions;

export default listSlice.reducer;
