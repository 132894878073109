import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadSettings, updateSetting } from "./../../store/admin/settingsSlice";
import { loadSchool } from "../../store/admin/adminSlice";

export const DataImport = () => {
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings.adminsettings);
    const school = useSelector((state) => state.admin.school);

    useEffect(() => {
        dispatch(loadSettings());
        dispatch(loadSchool());
    }, [dispatch]);

    const handleOnChangeSetting = (e) => {
        if (e.target.type === "checkbox") {
            dispatch(updateSetting({ name: e.target.name, value: e.target.checked }));
        } else {
            dispatch(updateSetting({ name: e.target.name, value: e.target.value }));
        }
    };

    const mapRender = (name, description, placeholder) => {
        return (
            <div key={name}>
                <label className="text-muted" htmlFor={name}>
                    {description}
                </label>
                <input
                    onChange={handleOnChangeSetting}
                    name={name}
                    value={settings[name]}
                    id={name}
                    className="form-control"
                    placeholder={placeholder}
                />
                <br />
            </div>
        );
    };

    const checkRender = (name, description) => {
        return (
            <div key={name}>
                <input name={name} type="checkbox" id={name} checked={settings[name]} onChange={handleOnChangeSetting} />
                &nbsp;
                <label className="text-muted" htmlFor={name}>
                    {description}
                </label>
                <br />
                <br />
            </div>
        );
    };

    const csvSettings = () => {
        if (school.data.csvimport) {
            return (
                <div>
                    <h4>CSV Settings</h4>
                    {checkRender("csv_importstudents", "Enable Student Import by CSV")}
                    {checkRender("csv_importstaff", "Enable Staff Import by CSV")}
                    {checkRender("csv_importconduct", "Enable Conduct Import by CSV")}

                    {mapRender("csv_delimiter", "Delimiter", "e.g. ,")}
                    {mapRender("csv_textqualifier", "Text Qualifier", "e.g. '")}
                    {mapRender("csv_interval", "Import interval (minutes)", "e.g. 15")}
                    {mapRender("csv_start", "Import time window begins", "e.g. 13")}
                    {mapRender("csv_end", "Import time window ends", "e.g. 15")}
                    {mapRender("csv_studentpath", "Path to student file on server", "e.g. /srv/dashboard/import")}
                    {mapRender("csv_staffpath", "Path to staff file on server", "e.g. /srv/dashboard/import")}
                    {mapRender("csv_achievementpath", "Path to achievement file on server", "e.g. /srv/dashboard/import")}
                    {mapRender("csv_behaviourpath", "Path to behaviour file on server", "e.g. /srv/dashboard/import")}

                    <hr />
                    <h4>CSV Student Column Maps</h4>
                    {mapRender("csv_column_student_fname", "First Name", "e.g. fname")}
                    {mapRender("csv_column_student_lname", "Last Name", "e.g. lname")}
                    {mapRender("csv_column_student_attendance", "Attendance", "e.g. attendance")}
                    {mapRender("csv_column_student_sen", "SEN", "e.g. sen")}
                    {mapRender("csv_column_student_pp", "PP", "e.g. pp")}
                    {mapRender("csv_column_student_lac", "LAC", "e.g. lac")}
                    {mapRender("csv_column_student_eal", "EAL", "e.g. eal")}
                    {mapRender("csv_column_student_exid", "External ID", "e.g. exid")}
                    {mapRender("csv_column_student_year", "Year", "e.g. 9")}
                    {mapRender("csv_column_student_tutor", "Tutor", "e.g. 10")}

                    <hr />
                    <h4>CSV Staff Column Maps</h4>
                    {mapRender("csv_column_staff_dispname", "Display Name", "e.g. name")}
                    {mapRender("csv_column_staff_code", "Code", "e.g. code")}
                    {mapRender("csv_column_staff_jtitle", "Job Title", "e.g. jtitle")}
                    {mapRender("csv_column_staff_exid", "External ID", "e.g. exid")}
                    {mapRender("csv_column_staff_tutorgroup", "Tutor Group", "e.g. tutor")}
                    {mapRender("csv_column_staff_faculty", "Faculty", "e.g. faculty")}
                    {mapRender("csv_column_staff_emailin", "Email", "e.g. email")}

                    <hr />
                    <h4>CSV Conduct Column Maps</h4>
                    {mapRender("csv_column_conduct_category", "Category", "e.g. category")}
                    {mapRender("csv_column_conduct_description", "Description", "e.g. description")}
                    {mapRender("csv_column_conduct_points", "Points", "e.g. points")}
                    {mapRender("csv_column_conduct_date", "Date Logged", "e.g. date")}
                    {mapRender("csv_column_conduct_resolved", "Date Resolved", "e.g. resolved")}
                    {mapRender("csv_column_conduct_staffcode", "Staff Code", "e.g. code")}
                    {mapRender("csv_column_conduct_studentid", "MIS Student ID", "e.g. id")}
                    {mapRender("csv_column_conduct_comment", "Comment", "e.g. comment")}
                    {mapRender("csv_column_conduct_action", "Action", "e.g. action")}
                    {mapRender("csv_column_conduct_classcode", "Class Code", "e.g. code")}
                    {mapRender("csv_column_conduct_department", "Department", "e.g. department")}
                    {mapRender("csv_column_conduct_exid", "External ID", "e.g. id")}
                </div>
            );
        }
        return null;
    };

    const wondeSettings = () => {
        if (school.data.csvimport) {
        return (
            <div>
                <h4>Wonde Settings</h4>
                {mapRender("csv_interval", "Import interval (minutes)", "e.g. 15")}
                {mapRender("csv_start", "Import time window begins", "e.g. 13")}
                {mapRender("csv_end", "Import time window ends", "e.g. 15")}
                {mapRender("wondetoken", "Wonde token", "e.g. 1542752485289254525245")}
            </div>
        );
    };
    };

    return (
        <div>
            <div>
                <p>
                    Currently importing?:{" "}
                    {school.data.importing ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>}
                </p>
                <p>Last import: {school.data.lastimported ?? ""}</p>
            </div>
            <hr />
            {csvSettings()}
            {wondeSettings()}
        </div>
    );
};
