import React from "react";
import "./App.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RootPage } from "./components/pages/RootPage";
import { ProtectedRoute } from "./components/pages/ProtectedRoute";
import { DashboardOverview } from "./components/dashboards/DashboardOverview";
import { DashboardBehaviourLog } from "./components/dashboards/DashboardBehaviourLog";
import { DashboardBehaviour } from "./components/dashboards/DashboardBehaviour";
import { DashboardAchievement } from "./components/dashboards/DashboardAchievement";
import { DashboardAchievementLog } from "./components/dashboards/DashboardAchievementLog";
import { DashboardAttendance } from "./components/dashboards/DashboardAttendance";
import { DashboardAttendanceLog } from "./components/dashboards/DashboardAttendanceLog";
import { Dashboard } from "./components/dashboards/Dashboard";
import { DashboardBehaviourTable } from "./components/dashboards/DashboardBehaviourTable";
import { DashboardAchievementTable } from "./components/dashboards/DashboardAchievementTable";
import { List } from "./components/lists/List";
import { useSelector } from "react-redux";
import { Admin } from "./components/admin/Admin";
import { Login } from "./components/pages/Login";
import { LoginReturn } from "./components/pages/LoginReturn";
import { Logout } from "./components/pages/Logout";
import { loadSubjects, loadFaculties, loadKeystages, loadStudents, loadTutorGroups, loadYearGroups, loadStaff } from "./store/lists/listSlice";
import {
    setFacultiesFilter,
    setSubjectsFilter,
    setKeystagesFilter,
    setStaffFilter,
    setStudentsFilter,
    setTutorgroupsFilter,
    setYeargroupsFilter,
} from "./store/lists/listSlice";
import { Profile } from "./components/pages/Profile";
import { LessonAttendance } from "./components/reports/LessonAttendance";

import { SubjectMapper } from "./components/admin/SubjectMapper";
import { TermDates } from "./components/admin/TermDates";
import { Users } from "./components/admin/Users";
import { Maintenance } from "./components/admin/Maintenance";
import { DataImport } from "./components/admin/DataImport";
import { Reports } from "./components/reports/Reports";
import { SessionAttendance } from "./components/reports/SessionAttendance";
import { HomeLearning } from "./components/reports/HomeLearning";

import { WholeSchool } from "./components/reports/SessionAttendanceItems/WholeSchool";
import { MainSchool } from "./components/reports/SessionAttendanceItems/MainSchool";
import { KS3 } from "./components/reports/SessionAttendanceItems/KS3";
import { KS4 } from "./components/reports/SessionAttendanceItems/KS4";
import { KS5 } from "./components/reports/SessionAttendanceItems/KS5";
import { Y7 } from "./components/reports/SessionAttendanceItems/Y7";
import { Y8 } from "./components/reports/SessionAttendanceItems/Y8";
import { Y9 } from "./components/reports/SessionAttendanceItems/Y9";
import { Y10 } from "./components/reports/SessionAttendanceItems/Y10";
import { Y11 } from "./components/reports/SessionAttendanceItems/Y11";
import { Y12 } from "./components/reports/SessionAttendanceItems/Y12";
import { Y13 } from "./components/reports/SessionAttendanceItems/Y13";

export const App = () => {
    const getDashboardSubRoute = () => {
        return (
            <>
                <Route index element={<Navigate to="Overview" replace={true} />} />
                <Route path="Overview" element={<DashboardOverview />} />
                <Route path="Behaviour" element={<DashboardBehaviour />} />
                <Route path="BehaviourLog" element={<DashboardBehaviourLog />} />
                <Route path="BehaviourTable" element={<DashboardBehaviourTable />} />
                <Route path="Achievement" element={<DashboardAchievement />} />
                <Route path="AchievementLog" element={<DashboardAchievementLog />} />
                <Route path="AchievementTable" element={<DashboardAchievementTable />} />
                <Route path="Attendance" element={<DashboardAttendance />} />
                <Route path="AttendanceLog" element={<DashboardAttendanceLog />} />
            </>
        );
    };

    const getSessionReportsSubRoute = () => {
        return (
            <>
                <Route index element={<Navigate to="WholeSchool" replace={true} />} />
                <Route path="WholeSchool" element={<WholeSchool />} />
                <Route path="MainSchool" element={<MainSchool />} />
                <Route path="KS3" element={<KS3 />} />
                <Route path="KS4" element={<KS4 />} />
                <Route path="KS5" element={<KS5 />} />
                <Route path="Y7" element={<Y7 />} />
                <Route path="Y8" element={<Y8 />} />
                <Route path="Y9" element={<Y9 />} />
                <Route path="Y10" element={<Y10 />} />
                <Route path="Y11" element={<Y11 />} />
                <Route path="Y12" element={<Y12 />} />
                <Route path="Y13" element={<Y13 />} />
            </>
        );
    };

    const getReportsSubRoute = () => {
        return (
            <>
                <Route index element={<Navigate to="LessonAttendance" replace={true} />} />
                <Route path="LessonAttendance" element={<LessonAttendance />} />
                <Route path="SessionAttendance" element={<SessionAttendance />}>{getSessionReportsSubRoute()}</Route>
                <Route path="HomeLearning" element={<HomeLearning />} />
            </>
        );
    };

    const getAdminSubRoute = () => {
        return (
            <>
                <Route index element={<Navigate to="Users" replace={true} />} />
                <Route path="Users" element={<Users />} />
                <Route path="Faculties" element={<SubjectMapper />} />
                <Route path="Terms" element={<TermDates />} />
                <Route path="DataImport" element={<DataImport />} />
                <Route path="Maintenance" element={<Maintenance />} />
            </>
        );
    };

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Navigate to="/Dashboard/WholeSchool" replace={true} />} />
                    <Route path="Login" element={<Login />} />
                    <Route path="Logout" element={<Logout />} />
                    <Route path="LoginReturn" element={<LoginReturn />} />
                    <Route path="/Dashboard" element={<RootPage />}>
                        <Route index element={<Navigate to="WholeSchool" replace={true} />} />
                        <Route
                            path="Admin"
                            element={
                                <ProtectedRoute>
                                    <Admin />
                                </ProtectedRoute>
                            }
                        >
                            {getAdminSubRoute()}
                        </Route>
                        <Route
                            path="Profile"
                            element={
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="Reports"
                            element={
                                <ProtectedRoute>
                                    <Reports />
                                </ProtectedRoute>
                            }>
                            {getReportsSubRoute()}
                        </Route>
                        <Route
                            path="Subjects"
                            element={
                                <ProtectedRoute>
                                    <List
                                        data={useSelector((state) => state.list.subjects)}
                                        loadCall={loadSubjects}
                                        savefilterCall={setSubjectsFilter}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="Faculties"
                            element={
                                <ProtectedRoute>
                                    <List
                                        data={useSelector((state) => state.list.faculties)}
                                        loadCall={loadFaculties}
                                        savefilterCall={setFacultiesFilter}
                                    />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="Students"
                            element={
                                <ProtectedRoute>
                                    <List
                                        data={useSelector((state) => state.list.students)}
                                        loadCall={loadStudents}
                                        savefilterCall={setStudentsFilter}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="KeyStages"
                            element={
                                <ProtectedRoute>
                                    <List
                                        data={useSelector((state) => state.list.keystages)}
                                        loadCall={loadKeystages}
                                        savefilterCall={setKeystagesFilter}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="YearGroups"
                            element={
                                <ProtectedRoute>
                                    <List
                                        data={useSelector((state) => state.list.yeargroups)}
                                        loadCall={loadYearGroups}
                                        savefilterCall={setYeargroupsFilter}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="TutorGroups"
                            element={
                                <ProtectedRoute>
                                    <List
                                        data={useSelector((state) => state.list.tutorgroups)}
                                        loadCall={loadTutorGroups}
                                        savefilterCall={setTutorgroupsFilter}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="Staff"
                            element={
                                <ProtectedRoute>
                                    <List
                                        data={useSelector((state) => state.list.staff)}
                                        loadCall={loadStaff}
                                        savefilterCall={setStaffFilter}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="Students/:id"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="student" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                        <Route
                            path="Faculties/:id"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="faculty" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                        <Route
                            path="KeyStages/:id"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="keystage" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                        <Route
                            path="YearGroups/:id"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="year" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                        <Route
                            path="TutorGroups/:id"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="tutor" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                        <Route
                            path="Staff/:id"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="staff" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                        <Route
                            path="Subjects/:id"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="subject" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                        <Route
                            path="WholeSchool"
                            element={
                                <ProtectedRoute>
                                    <Dashboard type="wholeschool" />
                                </ProtectedRoute>
                            }
                        >
                            {getDashboardSubRoute()}
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
};
