import React from 'react'

export const KS5 = () => {
  return (
    <div>
        <h3>Key Stage 5</h3>

    </div>
  )
}
